import React, { FC, forwardRef, useMemo } from 'react';
import { Category } from 'shared/types/product';
import { useFormat } from 'helpers/hooks/useFormat';
import { useProductList } from '../../context';

interface Props {
  categories: Category[];
  ref?: any;
}

const SortFacet: FC<React.PropsWithChildren<Props>> = forwardRef((props, ref) => {
  const categories: Category[] = props.categories;

  let elearningCategoryId = '';
  categories?.some((category) => {
    if (category.slug == 'course-type') {
      category?.subCategories?.some((subCategory) => {
        if (subCategory.slug == 'elearning-course-type') {
          if (subCategory?.categoryId) elearningCategoryId = subCategory.categoryId;
          return;
        }
      });
      return;
    }
  });

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { replaceSort, activeSort } = useProductList();

  const options = useMemo<Array<{ label: string; attribute: string; value: 'asc' | 'desc' }>>(() => {
    return [
      {
        label: formatProductMessage({ id: 'most.popular', defaultMessage: 'Most Popular' }),
        // attribute: 'categoryOrderHints.b3bf462d-19b8-4df1-a8ba-cbf4602e2b66',
        attribute: `categoryOrderHints.${elearningCategoryId}`,
        value: 'asc',
      },
      {
        label: formatProductMessage({ id: 'price.low.to.high', defaultMessage: 'Price - low to high' }),
        attribute: 'price',
        value: 'asc',
      },
      {
        label: formatProductMessage({ id: 'price.high.to.low', defaultMessage: 'Price - high to low' }),
        attribute: 'price',
        value: 'desc',
      },
      {
        label: formatProductMessage({ id: 'name.az', defaultMessage: 'Name a-z' }),
        attribute: 'name.en',
        value: 'asc',
      },
      {
        label: formatProductMessage({ id: 'name.za', defaultMessage: 'Name z-a' }),
        attribute: 'name.en',
        value: 'desc',
      },
    ];
  }, [elearningCategoryId, formatProductMessage]);

  return (
    <div>
      {options.map(({ label, attribute, value }, index) => (
        <div
          key={`${attribute}-${index}`}
          className={`cursor-pointer p-14 transition hover:bg-neutral-200 ${
            attribute === activeSort?.attribute ? 'bg-neutral-200' : 'bg-transparent'
          }`}
          onClick={() => replaceSort({ attribute, value })}
        >
          {label}
        </div>
      ))}
    </div>
  );
});

export default SortFacet;
SortFacet.displayName = 'SortFacet';
