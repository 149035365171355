import { AlertProps } from 'components/padi-ui/alert';

export class ErrorDisplay {
  static alert?: AlertProps;

  static setAlert = (error: AlertProps) => {
    ErrorDisplay.alert = error;
  };

  static clearAlert = () => {
    if (ErrorDisplay.alert) {
      ErrorDisplay.alert = undefined;
    }
  };
}
