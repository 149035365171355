import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { PromotionalTileCTAProps } from './types';

const TextLinkButton: React.FC<{ title: string; textColor: string }> = ({ title, textColor }) => {
  return (
    <div className={`flex items-center gap-10 ${textColor}`}>
      <span className="truncate">{title}</span>
      <span className="w-16">
        <ArrowRightIcon className="h-16" />
      </span>
    </div>
  );
};

const SecondaryButton: React.FC<{ title: string; borderColor: string; textColor: string }> = ({
  title,
  borderColor,
  textColor,
}) => {
  return (
    <div className={`flex min-h-36 items-center justify-center rounded-[6px] border ${borderColor} ${textColor}`}>
      <div className="truncate">{title}</div>
    </div>
  );
};

const PromotionalTileCTAButton: React.FC<PromotionalTileCTAProps> = ({ title, style, url, borderColor, textColor }) => {
  const ctaButton = useMemo(() => {
    if (style == 'secondary') {
      return <SecondaryButton title={title} borderColor={borderColor} textColor={textColor} />;
    }
    return <TextLinkButton title={title} textColor={textColor} />;
  }, [style, title, borderColor, textColor]);
  // @todo Removed per nextjs 15 upgrade. Nested <a> tags causing errors.
  // if (url) {
  //   return (
  //     <Link link={url}>
  //       <div className="text-14 font-medium">{ctaButton}</div>
  //     </Link>
  //   );
  // }
  return <div className="text-14 font-medium">{ctaButton}</div>;
};

export default PromotionalTileCTAButton;
