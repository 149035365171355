import Link from 'components/commercetools-ui/atoms/link';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import Image from 'frontastic/lib/image';
import { useEffect, useMemo, useRef, useState } from 'react';
import PromotionalTileCTAButton from './promotional-tile-cta-button';
import { PromotionalTileProps, PromotionalTileStyles } from './types';

function tileStyleProp(style: PromotionalTileStyles) {
  switch (style) {
    case PromotionalTileStyles.blueLight:
      return {
        background: 'bg-padi-blue-light',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
    case PromotionalTileStyles.grayLight:
      return {
        background: 'bg-padi-gray-light',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
    case PromotionalTileStyles.grayLighter:
      return {
        background: 'bg-padi-gray-lighter',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
    case PromotionalTileStyles.grayDarkest:
      return {
        background: 'bg-padi-gray-darkest',
        textColor: 'text-white',
        ctaBorderColor: 'border-white',
        ctaTextColor: 'text-white',
      };
    case PromotionalTileStyles.black:
      return {
        background: 'bg-black',
        textColor: 'text-white',
        ctaBorderColor: 'border-white',
        ctaTextColor: 'text-white',
      };
    case PromotionalTileStyles.imageBackgroundLight:
      return {
        background: '',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
    case PromotionalTileStyles.imageBackgroundDark:
      return {
        background: '',
        textColor: 'text-white',
        ctaBorderColor: 'border-white',
        ctaTextColor: 'text-white',
      };
    default:
      return {
        background: 'bg-padi-blue-light',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
  }
}

const PromotionalTile: React.FC<PromotionalTileProps> = ({
  title,
  subTitle,
  style,
  url,
  image,
  ctaStyle,
  bodyText,
  dataAnalytics,
}) => {
  const styleProp = tileStyleProp(style);
  const isShowTextLayer = title || subTitle || ctaStyle;
  const defaultBrightness = isShowTextLayer
    ? 'bg-gradient-to-b from-brightness-75 to-brightness-100'
    : 'brightness-100';
  const [imageHovered, setImageHovered] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const [overlayHeight, setOverlayHeight] = useState(0);
  const markBackground = useMemo(
    () => (styleProp.textColor == 'text-white' ? 'bg-padi-gray-darkest opacity-35' : 'bg-white opacity-70'),
    [styleProp.textColor],
  );

  useEffect(() => {
    const height = (textRef?.current?.offsetHeight || 0) + 40; // 40 = padding top + padding bottom
    setOverlayHeight(height);
  }, []);

  return (
    <Link link={url} padi-analytics={dataAnalytics}>
      <div
        className={`relative flex size-full min-h-360 rounded-lg ${styleProp.background}`}
        onMouseEnter={() => setImageHovered(true)}
        onMouseLeave={() => setImageHovered(false)}
      >
        {image?.media?.file && (
          <>
            <Image {...image} className="absolute size-full rounded-lg object-cover" />
            {isShowTextLayer && (
              <div className="absolute inset-x-0 bottom-0 rounded-lg">
                <div
                  className={`rounded-b-lg ${markBackground} ${imageHovered ? 'brightness-[1.2]' : defaultBrightness}`}
                  style={{ height: overlayHeight }}
                />
              </div>
            )}
          </>
        )}
        {isShowTextLayer && (
          <div className={`z-10 flex flex-col self-end px-30 pb-30 pt-10 ${styleProp.textColor}`} ref={textRef}>
            {title && <div className="text-lg font-semibold">{title}</div>}
            {bodyText && (
              <Markdown
                className={`prose z-10 mt-5 text-base marker:${styleProp.ctaTextColor} ${styleProp.textColor}`}
                markdown={bodyText}
              />
            )}
            {subTitle && <div className="text-base font-regular">{subTitle}</div>}
            {ctaStyle && (
              <div className="pt-20">
                <PromotionalTileCTAButton
                  title={ctaStyle?.title}
                  style={ctaStyle?.style}
                  url={url}
                  borderColor={styleProp.ctaBorderColor}
                  textColor={styleProp.textColor}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Link>
  );
};

export default PromotionalTile;
