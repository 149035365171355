import React, { useMemo, useState, useEffect } from 'react';
import { Category } from 'shared/types/product/Category';
import { Product } from 'shared/types/product/Product';
import Alert, { AlertProps } from 'components/padi-ui/alert';
import { PromotionalTileProps } from 'components/padi-ui/promotional-tile/types';
import { ErrorDisplay } from 'helpers/errorDisplay';
import { useFormat } from 'helpers/hooks/useFormat';
import { ProjectConfig } from 'types/project-config';
import AccumalativeTrace from './components/accumalative-trace';
import Breadcrumbs from './components/breadcrumb';
import CategoryMenu from './components/category-menu';
import CurrentRefinements from './components/current-refinements';
import DesktopFacets from './components/desktop-facets';
import List from './components/list';
import MobileFacets from './components/mobile-facets';
import { useProductList } from './context';

export interface Props {
  categories: Category[];
  products: Product[];
  projectConfig?: ProjectConfig;
  promotionalTileProps?: PromotionalTileProps;
}

const ProductList: React.FC<Props> = ({ products, categories, projectConfig, promotionalTileProps }) => {
  const { slug } = useProductList();

  const { formatMessage: formatMessageProduct } = useFormat({ name: 'product' });

  const [alert, setAlert] = useState<AlertProps>();
  useEffect(() => {
    if (ErrorDisplay.alert) {
      setAlert({ ...ErrorDisplay.alert });
      ErrorDisplay.clearAlert();
    }
  }, [alert]);

  let activeCategory = useMemo(() => categories.find((category) => category.slug === slug), [categories, slug]);
  let activeParentCategory = {} as Category;

  const findActiveCategory = (categories: Category[] | undefined) => {
    if (!categories) return;
    categories.map(function (category) {
      if (category?.subCategories) {
        category.subCategories?.some((subCategory) => {
          if (subCategory.slug === slug) {
            activeCategory = subCategory;
            activeParentCategory = category;
          } else {
            findActiveCategory(category.subCategories);
          }
        });
      }
    });
  };

  if (!activeCategory) {
    findActiveCategory(categories);
  }

  return (
    <div className="min-h-screen bg-white py-48">
      {alert && (
        <div className="flex justify-center">
          <Alert alertType={`${alert.alertType}`} body={`${alert.body}`} />
        </div>
      )}
      <Breadcrumbs
        categories={activeParentCategory?.subCategories ?? categories}
        categoryId={activeCategory?.categoryId}
      />
      <div className="mx-auto max-w-7xl px-24 lg:px-32">
        <MobileFacets categories={categories} activeCategory={activeCategory ?? {}} />
        <main>
          <DesktopFacets {...{ categories }} />

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              {formatMessageProduct({
                id: 'products',
                defaultMessage: 'Products',
              })}
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <div className="hidden pt-32 lg:block">
                <CategoryMenu categories={categories} activeCategory={activeCategory ?? {}} />
              </div>

              {/* Product grid */}
              <div className="lg:col-span-3">
                <div className="relative">
                  <CurrentRefinements />
                  <List products={products} projectConfig={projectConfig} promotionalTileProps={promotionalTileProps} />
                  <AccumalativeTrace currentItems={products.length} />
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default ProductList;
